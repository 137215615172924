import { BorderLink } from '@playbooks/interface/links';
import { CollectionBadges } from 'components/collection/collection-badges';
import { CollectionCardActions } from 'components/collection/collection-card-actions';
import { CollectionCardHeader } from 'components/collection/collection-card-header';
import { CollectionCardMeta } from 'components/collection/collection-card-meta';
import {
	ActionCard,
	DetailCard,
	DisplayCard,
	GridCard,
	InfoCard,
	ListCard,
	PhotoLinkCard,
	PillCard,
	PreviewCard,
	SearchBtnCard,
	SelectCard,
} from 'molecules/cards';
import { formatDate } from 'utils';

export const CollectionActionCard = ({ collection, loading, children, tailwind }) => {
	// Render
	return (
		<ActionCard
			icon='hand-point-up'
			photo={collection.thumbnail}
			title={collection.name}
			subtitle={collection.description}
			meta={<CollectionCardMeta type='card' collection={collection} />}
			loading={loading}
			tailwind={tailwind}>
			{children}
		</ActionCard>
	);
};

export const CollectionDetailCard = ({ collection, loading, tailwind }) => {
	// Render
	return (
		<DetailCard
			icon='layer-group'
			status={collection.status}
			cover={collection.cover}
			photo={collection.thumbnail}
			title={collection.name}
			subtitle={collection.owner?.name}
			text={collection.description}
			footer={collection.updatedAt}
			loading={loading}
			tailwind={tailwind}
		/>
	);
};

export const CollectionDisplayCard = ({ collection, rootLink, children, tailwind }) => {
	const computedHref =
		collection.ownerType === 'Team'
			? `${rootLink}/teams/${collection.owner.uuid}/collections/${collection.uuid}`
			: `${rootLink}/${collection.owner.uuid}/collections/${collection.uuid}`;
	// Render
	return (
		<DisplayCard
			icon='layer-group'
			badges={<CollectionBadges collection={collection} />}
			header={<CollectionCardHeader type='display' collection={collection} />}
			status={collection.visibility}
			title={collection.name}
			subtitle={collection.description}
			href={computedHref}
			meta={<CollectionCardMeta type='grid' collection={collection} />}
			tailwind={tailwind}>
			{children ? children : <CollectionCardActions type='grid' collection={collection} rootLink={rootLink} />}
		</DisplayCard>
	);
};

export const CollectionGridCard = ({ collection, loading, rootLink, tailwind }) => {
	// Render
	return (
		<GridCard
			icon='layer-group'
			header={<CollectionCardHeader type='grid' collection={collection} />}
			status={collection.visibility}
			title={collection.name}
			subtitle={collection.description}
			text={collection.description}
			href={`${rootLink}/collections/${collection.uuid}`}
			meta={<CollectionCardMeta type='grid' collection={collection} loading={loading} />}
			tailwind={tailwind}>
			<CollectionCardActions type='grid' collection={collection} rootLink={rootLink} />
		</GridCard>
	);
};

export const CollectionInfoCard = ({ collection, loading, tailwind }) => {
	// Render
	return (
		<InfoCard
			icon='hand-point-up'
			status={collection.status}
			photo={collection.thumbnail}
			title={collection.name}
			subtitle={collection.description}
			meta={<CollectionCardMeta type='card' collection={collection} />}
			loading={loading}
			tailwind={tailwind}
		/>
	);
};

export const CollectionListCard = ({ collection, loading, onClick, rootLink, tailwind }) => {
	// Render
	return (
		<ListCard
			icon='layer-group'
			badges={<CollectionBadges collection={collection} size='xs' />}
			header={<CollectionCardHeader type='list' collection={collection} />}
			status={collection.visibility}
			title={collection.name}
			subtitle={collection.description}
			href={`${rootLink}/collections/${collection.uuid}`}
			meta={<CollectionCardMeta type='list' collection={collection} loading={loading} />}
			loading={loading}
			tailwind={tailwind}>
			<CollectionCardActions type='list' collection={collection} rootLink={rootLink} />
		</ListCard>
	);
};

export const CollectionPhotoLinkCard = ({ collection, loading, rootLink, children, tailwind }) => {
	// Render
	return (
		<PhotoLinkCard
			icon='layer-group'
			photo={collection.thumbnail}
			title={collection.name}
			subtitle={collection.owner?.name}
			href={`${rootLink}/collections/${collection.uuid}`}
			tailwind={tailwind}>
			{children}
		</PhotoLinkCard>
	);
};

export const CollectionPillCard = ({ collection, onClick, loading, rootLink, tailwind }) => {
	// Render
	return (
		<PillCard
			icon='layer-group'
			photo={collection.thumbnail}
			title={collection.name}
			subtitle={collection.owner?.name}
			href={`${rootLink}/collections/${collection.uuid}`}
			tailwind={tailwind}
		/>
	);
};

export const CollectionPreviewCard = ({ collection, loading, rootLink, tailwind }) => {
	// Render
	return (
		<PreviewCard
			icon='layer-group'
			photo={collection.thumbnail}
			title={collection.name}
			text={collection.owner?.name}
			href={`${rootLink}/collections/${collection.uuid}`}
			tailwind={tailwind}>
			<BorderLink size='icon' icon='chevron-right' href={`${rootLink}/collections/${collection.uuid}`} />
		</PreviewCard>
	);
};

export const CollectionSearchCard = ({ collection, onClick, tailwind }) => {
	// Render
	return (
		<SearchBtnCard
			icon='layer-group'
			modelName='collection'
			photo={collection.thumbnail}
			title={collection.name}
			subtitle={collection.owner?.name}
			onClick={() => onClick(collection)}
			tailwind={tailwind}
		/>
	);
};

export const CollectionSelectCard = ({ collection, selected = [], onClick, tailwind }) => {
	// Render
	return (
		<SelectCard
			icon='layer-group'
			photo={collection.thumbnail}
			title={collection.name}
			text={`${collection.repos?.length} repos`}
			selected={selected.map(v => v.id).includes(collection.id)}
			onClick={() => onClick(collection)}
			tailwind={tailwind}
		/>
	);
};

export const CollectionSubmitCard = ({ collection, taskRunning, onClick, tailwind }) => {
	// Render
	return (
		<ActionCard
			icon='layer-group'
			photo={collection.thumbnail}
			status={collection.status}
			title={collection.name}
			text={'Last updated ' + formatDate(collection.updatedAt)}
			tailwind={tailwind}>
			<CollectionCardActions type='submit' collection={collection} taskRunning={taskRunning} onClick={onClick} />
		</ActionCard>
	);
};
