import { Fragment } from 'react';

import { Badge } from '@playbooks/interface/badges';
import { Span } from '@playbooks/interface/html';

const CollectionBadges = ({ collection, ...props }) => {
	return (
		<Fragment>
			{collection.featured && (
				<Badge borderColor='border-green-500' color='green-500' {...props}>
					<Span>Featured</Span>
				</Badge>
			)}
		</Fragment>
	);
};

export { CollectionBadges };
