import { Div } from '@playbooks/interface/html';
import { AccentLink } from '@playbooks/interface/links';
import { metaCardProps } from '@playbooks/interface/utils';

export const CollectionCardMeta = ({ collection, ...props }) => {
	const items = [...(collection.repos || []), ...(collection.bounties || [])];
	const platform = items.find(v => v.platform)?.platform || {};
	const language = items.find(v => v.language)?.language || {};
	const framework = items.find(v => v.framework)?.framework || {};
	const topic = items.find(v => v.topic)?.topic || {};

	return (
		<Div display='flex-start flex-wrap' spacing='py-1' {...props}>
			{platform.id && (
				<AccentLink href={`/platforms/${platform?.uuid}`} {...metaCardProps}>
					{platform?.name}
				</AccentLink>
			)}
			{language.id && (
				<AccentLink href={`/languages/${language?.uuid}`} {...metaCardProps}>
					{language?.name}
				</AccentLink>
			)}
			{framework.id && (
				<AccentLink href={`/frameworks/${framework?.uuid}`} {...metaCardProps}>
					{framework?.name}
				</AccentLink>
			)}
			{topic.id && (
				<AccentLink href={`/topics/${topic?.uuid}`} {...metaCardProps}>
					{topic?.name}
				</AccentLink>
			)}
		</Div>
	);
};
